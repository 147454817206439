<template>
  <div class="ma-4" style="height:100%">
    <admin-header>
      <template v-slot:left>
        <v-select v-model="calendarView" :items="calendarOptions" label="Calendar View" class="ml-6" dense outlined hide-details @change="loadSlots()" style="max-width:200px"></v-select>
      </template>
      <template v-slot:right>
        <jury-help-dialog></jury-help-dialog>
      </template>
    </admin-header>
    <v-card class="mt-4">
      <v-row>
        <v-col cols="9">
          <v-sheet height="100%">
            <v-calendar
              ref="calendar"
              :events="filteredEvents"
              :start="start"
              :end="end"
              :weekdays="weekdays"
              type="week"
              first-interval="30"
              interval-minutes="20"
              interval-count="15"
              @click:event="clickEvent"
              ></v-calendar>
          </v-sheet>
          <v-dialog v-model="editDialog" width="400">
            <v-card>
              <v-card-title>Edit Inactive Slot</v-card-title>
              <v-card-text>
                <p>Editing slot on {{ editTime }}</p>
                <v-tabs v-model="editTab">
                  <v-tab>Activate</v-tab>
                  <v-tab>Assign Student</v-tab>
                </v-tabs>
                <v-tabs-items v-model="editTab">
                  <v-tab-item>
                    <p>To activate the slot so students can choose it, click the button below.</p>
                    <v-btn color="success">Activate Slot</v-btn>
                  </v-tab-item>
                  <v-tab-item>
                    <p>To assign a student to this slot, select the student, their instrument, and their instructor, then click "Save".</p>
                    <directory-search v-model="editStudent" :person-filter="['Student']" label="Student Search"></directory-search>
                    <v-select v-model="editInstructor" :items="instructors" label="Instructor" item-value="name" item-text="name"></v-select>
                    <v-select v-model="editInstrument" :items="instruments" label="Instrument"></v-select>
                    <v-btn color="success" :disabled="editStudent === '' || editInstructor === '' || editInstrument === ''">Save</v-btn>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="editDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-4 mr-4" style="height:100%">
            <v-card-text>
              <div v-if="calendarView === 'instr'">
                <v-btn :disabled="filter.avail.instr.length !== 1" outlined>Edit Times</v-btn>
                <v-list dense>
                  <v-list-item v-for="{ name, color } in instructors" :key="name" class="pl-0 pr-0" @click="toggleAvail('instr', name)">
                    <v-list-item-action class="mr-1">
                      <v-icon small>{{ filter.avail.instr.includes(name) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <v-icon :color="color" small>fas fa-square</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-dialog v-model="addInstructorDialog" width="300">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Add Instructor</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Add Instructor</v-card-title>
                    <v-card-text>
                      <v-text-field v-model="addInstructor" label="Instructor Name" outlined></v-text-field>
                      <v-btn small @click="addInstructorTimes = addTimeOptions.map(({ value }) => value)">Check All</v-btn>
                      <v-list dense>
                        <v-list-item v-for="{ value, text } in addTimeOptions" :key="value" class="pl-0 pr-0" @click="toggleTime('instr', value)">
                          <v-list-item-action class="mr-1">
                            <v-icon small>{{ addInstructorTimes.includes(value) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-title>{{ text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="addInstructorDialog = false">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" text @click="addAvail('instructor')">Add</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else-if="calendarView === 'accomp'">
                <v-list dense>
                  <v-list-item v-for="{ text } in accompanists" :key="text" class="pl-0 pr-0" @click="toggleAvail('accomp', text)">
                    <v-list-item-action class="mr-1">
                      <v-icon small>{{ filter.avail.accomp.includes(text) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-dialog v-model="addAccompanistDialog" width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">Add Accompanist</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Add Accompanist</v-card-title>
                    <v-card-text>
                      <v-text-field></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text @click="addAccompanistDialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div v-else-if="calendarView === 'slots'">
                <v-checkbox v-model="filter.activeOnly" label="Show Active Slots Only" class="mt-0"></v-checkbox>
                <v-text-field v-model="filter.name" label="Name Search" outlined></v-text-field>
                <v-select v-model="instrumentFilter" :items="instruments" label="Instruments" outlined multiple chips small-chips>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-icon>{{ instrumentFilter.includes(item.value) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.count }} Slots</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-select v-if="selectedInstructors.length > 0" v-model="instructorFilter" :items="selectedInstructors" label="Selected Instructors" outlined multiple chips small-chips>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-icon>{{ instructorFilter.includes(item.value) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.count }} Slots</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-select v-if="selectedAccompanists.length > 0" v-model="filter.accompanists" :items="selectedAccompanists" label="Selected Accompanists" outlined multiple chips small-chips>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-icon>{{ filter.selectedAccompanists.includes(item.value) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.count }} Slots</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-divider></v-divider>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" outlined>Actions</v-btn>
                  </template>
                  <v-list style="max-width:355px">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Inactivate Open Slots</v-list-item-title>
                        <v-list-item-subtitle>Makes any active, open slot unavailable to students</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Activate Open Slots</v-list-item-title>
                        <v-list-item-subtitle>Makes all inactive slots available to students</v-list-item-subtitle>
                        <v-list-item-subtitle>Except those overlapping populated slots</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/adminHeader'),
    JuryHelpDialog: () => import('@/components/student/music/juryHelpDialog'),
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('student/music/jury-slot')

    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })

    const calendarView = ref('slots')
    const calendarOptions = ref([{ text: 'Student Slots', value: 'slots' }, { text: 'Instructor Availability', value: 'instr' }, { text: 'Accompanist Availability', value: 'accomp' }])

    const slots = ref([])
    const events = ref([])
    const start = ref('2024-04-26')
    const end = ref('2024-04-30')
    const weekdays = ref([5, 1, 2])

    const instructors = ref([])
    const accompanists = ref([])
    const instruments = ref([])
    const selectedInstructors = ref([])
    const selectedAccompanists = ref([])
    const filter = ref({
      activeOnly: false,
      avail: {
        instr: [],
        accomp: []
      },
      name: '',
      accompanists: []
    })
    const instructorFilter = computed({
      get: () => root.$store.state.student.music.admin.instructor,
      set: (instructor) => root.$store.commit('student/setMusicAdmin', { instructor })
    })
    const instrumentFilter = computed({
      get: () => root.$store.state.student.music.admin.instrument,
      set: (instrument) => root.$store.commit('student/setMusicAdmin', { instrument })
    })
    function toggleAvail (field, text) {
      for (let i = 0; i < filter.value.avail[field].length; i++) {
        if (filter.value.avail[field][i] === text) {
          filter.value.avail[field].splice(i, 1)
          return
        }
      }
      filter.value.avail[field].push(text)
    }

    const filteredEvents = computed(() => events.value.filter(({ name, active, instructor, instrument, accompanist }) => {
      switch (calendarView.value) {
        case 'instr':
        case 'accomp':
          return filter.value.avail[calendarView.value].length === 0 || filter.value.avail[calendarView.value].includes(name)
        case 'slots':
          if (filter.value.activeOnly && !active) return false
          if (instructorFilter.value.length > 0 && !instructorFilter.value.includes(instructor)) return false
          if (instrumentFilter.value.length > 0 && !instrumentFilter.value.includes(instrument)) return false
          if (filter.value.accompanists.length > 0 && !filter.value.accompanists.includes(accompanist)) return false
          if (filter.value.name !== '' && name.search(new RegExp(filter.value.name, 'ig')) < 0) return false
          return true
      }
    }))

    watch(term, () => {
      loadInstructors()
      loadSlots()
      loadFilters()
    })

    async function loadInstructors () {
      const aggregate = [
        { $match: { term: term.value } },
        { $unwind: '$avail.instr' },
        { $group: { _id: '$avail.instr' } },
        { $addFields: { name: '$_id' } },
        { $sort: { name: 1 } }
      ]
      const { data } = await service.find({ query: { aggregate } })
      const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow lighten-1', 'amber lighten-1', 'orange ligten-1', 'deep-orange', 'brown', 'blue-grey']
      instructors.value = data.map(({ name }, index) => { return { name, color: colors[index] } })
    }

    async function loadSlots () {
      events.value = []
      const $match = { term: term.value }
      const aggregate = [{ $match }]
      switch (calendarView.value) {
        case 'instr':
        case 'accomp':
          aggregate.push({ $unwind: '$avail.' + calendarView.value })
          aggregate.push({ $project: {
            name: '$avail.' + calendarView.value,
            start: 1,
            end: 1
          } })
          // if (filter.value.avail[calendarView.value].length > 0) {
          //   aggregate.push({ $match: { name: { $in: filter.value.avail[calendarView.value] } } })
          // }
          aggregate.push({ $group: {
            _id: '$name',
            times: {
              $push: {
                start: '$start',
                end: '$end'
              }
            }
          } })
          aggregate.push({ $project: {
            name: '$_id',
            times: { $sortArray: { input: '$times', sortBy: { start: 1 } } }
          } })
          aggregate.push({ $sort: { name: 1 } })
          break
        case 'slots':
          aggregate.push({ $lookup: {
            from: 'Directory',
            localField: 'student',
            foreignField: '_id',
            as: 'student',
            pipeline: [
              { $project: {
                name: { $concat: ['$name.first', ' ', '$name.last'] }
              } }
            ]
          } })
          aggregate.push({ $project: {
            _id: 1,
            start: 1,
            end: 1,
            active: 1,
            instrument: 1,
            instructor: 1,
            accompanist: 1,
            student: { $first: '$student' }
          } })
          aggregate.push({ $project: {
            _id: 1,
            start: 1,
            end: 1,
            active: 1,
            instrument: 1,
            instructor: 1,
            accompanist: 1,
            name: {
              $ifNull: ['$student.name', { $cond: { if: { $eq: ['$active', true] }, then: 'Open', else: 'Inactive' } }]
            },
            color: {
              $cond: {
                if: { $eq: [{ $ifNull: ['$student.name', ''] }, ''] },
                then: { $cond: { if: { $eq: ['$active', true] }, then: 'grey', else: 'black' } },
                else: 'success' }
            }
          } })
          // if (filter.value.name !== '') {
          //   aggregate.push({ $match: { name: { $regex: filter.value.name, $options: 'i' } } })
          // }
          aggregate.push({ $sort: { start: 1 } })
      }
      const { data } = await service.find({ query: { aggregate } })
      if (calendarView.value === 'slots') {
        events.value = data.map((row) => {
          return { ...row, start: new Date(row.start), end: new Date(row.end), timed: true }
        })
      } else {
        if (calendarView.value === 'instr') {
          instructors.value = []
        } else if (calendarView.value === 'accomp') {
          accompanists.value = []
        }
        const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow lighten-1', 'amber lighten-1', 'orange ligten-1', 'deep-orange', 'brown', 'blue-grey']
        for (let j = 0; j < data.length; j++) {
          const { name, times } = data[j]
          const color = j in colors ? colors[j] : colors[j - 18].replace('lighten-1', '') + ' darken-3'
          let start = ''
          let end = ''
          for (let i = 0; i < times.length; i++) {
            if (start === '') start = times[i].start
            if (end === '' || end === times[i].start || times[i].start < end) {
              // console.log('Not adding event', { start, end })
              end = times[i].end
            } else {
              events.value.push({ name, color, start: new Date(start), end: new Date(end), timed: true })
              start = times[i].start
              end = times[i].end
            }
            const dt = new Date(start)
            const temp = addTimeOptions.value.filter(({ value }) => value === dt.toISOString().substring(0, 10) + '-AM')
            if (temp.length === 0) {
              addTimeOptions.value.push({ value: dt.toISOString().substring(0, 10) + '-AM', text: stringFormatDate(dt, true, true) + ' - Morning' })
              addTimeOptions.value.push({ value: dt.toISOString().substring(0, 10) + '-PM', text: stringFormatDate(dt, true, true) + ' - Afternoon' })
            }
          }
          events.value.push({ name, color, start: new Date(start), end: new Date(end), timed: true })
          if (calendarView.value === 'instr') {
            instructors.value.push({ name, color })
          } else if (calendarView.value === 'accomp') {
            accompanists.value.push({ name, color })
          }
        }
        addTimeOptions.value = addTimeOptions.value.sort((a, b) => {
          return a.value <= b.value ? -1 : 1
        })
      }
    }

    async function loadFilters () {
      // instructors.value = await loadFilter('avail.instr', true)
      // accompanists.value = await loadFilter('avail.accomp', true)
      instruments.value = await loadFilter('instrument', false)
      selectedInstructors.value = await loadFilter('instructor', false)
      selectedAccompanists.value = await loadFilter('accompanist', false)
    }

    async function loadFilter (field, doUnwind) {
      if (field.substring(0, 1) !== '$') field = '$' + field
      const aggregate = [
        { $match: { term: term.value } },
        { $unwind: field },
        { $group: {
          _id: field,
          count: { $sum: 1 }
        } },
        { $sort: { _id: 1 } },
        { $project: {
          text: { $ifNull: ['$_id', 'None'] },
          value: '$_id',
          count: 1
        } }
      ]
      if (doUnwind === false) {
        aggregate.splice(1, 1)
        aggregate[0].$match[field.substring(1)] = { $ne: null }
      }
      const { data } = await service.find({ query: { aggregate } })
      return data
    }

    async function clickEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()
      console.log(event)
      if (event.name.substring(0, 4) === 'Open' || event.name.substring(0, 8) === 'Inactive') {
        editTime.value = stringFormatDate(event.start)
        editId.value = event._id
        editDialog.value = true
      } else if (calendarView.value === 'slots') {
        root.$router.push('/student/music/jury/' + event._id)
      } else if (calendarView.value === 'instr') {
        instructorFilter.value = [event.name]
        calendarView.value = 'slots'
        loadSlots()
      }
    }

    const addTimeOptions = ref([])
    const addInstructorDialog = ref(false)
    const addInstructor = ref('')
    const addInstructorTimes = ref([])
    const addAccompanistDialog = ref(false)
    const addAccompanist = ref('')
    const addAccompanistTimes = ref([])
    function addAvail (field) {
      if (field === 'instructor') {
        instructors.value.push({ text: addInstructor.value, value: addInstructor.value })
        filter.value.avail.instr = [addInstructor.value]
        addInstructorDialog.value = false
        addInstructor.value = ''
      } else if (field === 'accompanist') {
        accompanists.value.push({ text: addAccompanist.value, value: addAccompanist.value })
        filter.value.avail.accomp = [addAccompanist.value]
        addAccompanistDialog.value = false
        addAccompanist.value = ''
      }
    }
    function toggleTime (type, value) {
      if (type === 'instr') {
        for (let i = 0; i < addInstructorTimes.value.length; i++) {
          if (addInstructorTimes.value[i] === value) {
            addInstructorTimes.value.splice(i, 1)
            return
          }
        }
        addInstructorTimes.value.push(value)
      } else if (type === 'accom') {
        for (let i = 0; i < addAccompanistTimes.value.length; i++) {
          if (addAccompanistTimes.value[i] === value) {
            addAccompanistTimes.value.splice(i, 1)
            return
          }
        }
        addAccompanistTimes.value.push(value)
      }
    }

    const editDialog = ref(false)
    const editId = ref('')
    const editTime = ref('')
    const editTab = ref(0)
    const editStudent = ref('')
    const editInstrument = ref('')
    const editInstructor = ref('')

    return {
      term,
      calendarView,
      calendarOptions,
      slots,
      events,
      start,
      end,
      weekdays,
      instructors,
      accompanists,
      instruments,
      selectedInstructors,
      selectedAccompanists,
      filter,
      instrumentFilter,
      instructorFilter,
      toggleAvail,
      filteredEvents,
      loadSlots,
      clickEvent,
      addTimeOptions,
      addInstructorDialog,
      addInstructor,
      addInstructorTimes,
      addAccompanistDialog,
      addAccompanist,
      addAccompanistTimes,
      addAvail,
      toggleTime,
      editDialog,
      editId,
      editTime,
      editTab,
      editStudent,
      editInstrument,
      editInstructor,
      stringFormatDate
    }
  }
}
</script>
